import { Button, Flex, HStack, InvertTheme, Text, Image } from '@stoplight/mosaic';
import axios from 'axios';
import React, { useContext } from 'react';
import { GlobalContext } from '../context';

export const DemoNavbar = () => {
    const { apiDescriptionUrl } = useContext(GlobalContext);
    const logoSrc = 'https://resources.public.infigosoftware.rocks/logo/infigo-logo-white.svg';

    const downloadStringAsFile = (content: string, fileName: string, contentType: string) => {
        const blob = new Blob([content], { type: contentType });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
    };

    const downloadData = () => {
        axios.get(apiDescriptionUrl).then(it => {
            downloadStringAsFile(it.data, 'OpenAPI.yaml', 'application/x-yaml');
        });
    };

    return (
        <>
            <InvertTheme>
                <Flex h="2xl" shrink={0} px={5} alignItems="center" bg="canvas-pure" style={{ backgroundColor: "#002e47" }}>
                    <HStack w="1/3" alignItems="center" spacing={4}>
                        <Image id={"image-logo"} src={logoSrc} alt="Infigo Logo" height="100px" width="100px" />
                        <Text fontSize="lg" fontWeight="semibold" lineHeight="none" style={{ color: "#FFFFFF" }}>
                            Infigo API Documentation
                        </Text>
                        <Button className={"header-download-button"} onClick={() => downloadData()}>Download Open API</Button>
                    </HStack>
                </Flex>
            </InvertTheme>
        </>
    );
};