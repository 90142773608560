import { createContext } from 'react';

import { DEFAULT_API_URL } from './constants';

export type GlobalContext = {
  apiDescriptionUrl: string;
  setDescriptionUrl: (value: string) => void;
  buildNumber: number;
  platformUrl: string;
  setDetails: (buildNumber: number, platformUrl: string) => void;
};

export const defaultGlobalContext: GlobalContext = {
  apiDescriptionUrl: DEFAULT_API_URL,
  buildNumber: 0,
  platformUrl: '',
  setDetails: (buildNumber: number, platformUrl: string) => {},
  setDescriptionUrl: () => {},
};

export const GlobalContext = createContext<GlobalContext>(defaultGlobalContext);
