export function appendQueryParameter(url: string, name: string, value: string | number | null) {
  let newUrl = url;
  if (newUrl.length === 0) {
    return;
  }

  if (!value) {
    return newUrl;
  }

  if (newUrl.indexOf('?') > -1) {
    newUrl += `&${name}=${value}`;
  } else {
    newUrl += `?${name}=${value}`;
  }
  return newUrl;
}
