import '@stoplight/elements-core/styles.css';

import { API } from '@stoplight/elements';
import { Box } from '@stoplight/mosaic';
import React, { useContext } from 'react';

import { GlobalContext } from '../context';

export const ElementsAPI: React.FC = () => {
  const { apiDescriptionUrl, platformUrl } = useContext(GlobalContext);

  return (
    <Box flex={1} overflowY="hidden">
      <API apiDescriptionUrl={apiDescriptionUrl} hideTryIt={!platformUrl} router="hash" />
    </Box>
  );
};
