import { Flex, Provider as MosaicProvider, useIconStore } from '@stoplight/mosaic';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DemoNavbar } from './components/DemoNavbar';
import { ElementsAPI } from './components/ElementsAPI';
import { DEFAULT_API_URL } from './constants';
import { GlobalContext } from './context';
import { appendQueryParameter } from './query.helper';
import './styles/style.css';

export function App() {
  const [searchParams] = useSearchParams();
  const setDefaultStyle = useIconStore(state => state.setDefaultStyle);

  React.useEffect(() => {
    setDefaultStyle('fal');
    setGlobalState(prevState => {
      return {
        ...prevState,
        apiDescriptionUrl: buildOpenApiUrl(),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildOpenApiUrl = () => {
    let url = DEFAULT_API_URL;

    const platformUrl = searchParams.get('platformUrl');
    const buildNumber = searchParams.get('buildNumber');

    url = appendQueryParameter(url, 'platformUrl', platformUrl) || '';
    url = appendQueryParameter(url, 'buildNumber', buildNumber) || '';

    return url;
  };

  const [globalState, setGlobalState] = useState<GlobalContext>({
    buildNumber: 0,
    platformUrl: '',
    apiDescriptionUrl: buildOpenApiUrl(),
    setDescriptionUrl: _value => {
      setTimeout(() => {
        setGlobalState({
          ...globalState,
          apiDescriptionUrl: _value,
        });
      }, 0);
    },
    setDetails: (buildNumber, platformUrl) => {
      setTimeout(() => {
        setGlobalState({
          ...globalState,
          buildNumber: buildNumber,
          platformUrl: platformUrl,
        });
      }, 0);
    },
  });

  useEffect(() => {
    setTimeout(() => {
      const platformUrl = searchParams.get('platformUrl');
      const buildNumber = searchParams.get('version');

      setGlobalState({
        ...globalState,
        buildNumber: parseInt(buildNumber ?? '') > 0 ? parseInt(buildNumber ?? '') : 0,
        platformUrl: platformUrl ?? '',
      });
    }, 0);
  }, [searchParams]);

  return (
    <MosaicProvider>
      <GlobalContext.Provider value={globalState}>
        <Flex direction="col" bg="canvas" h="screen">
          <DemoNavbar />
          <ElementsAPI />
        </Flex>
      </GlobalContext.Provider>
    </MosaicProvider>
  );
}
